import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import start from "./pages/start";
import result from "./pages/result";
import step1 from './pages/step1'
import step2 from './pages/step2'
import step3 from './pages/step3'
import step4 from './pages/step4'
import step5 from './pages/step5'
import nav from './views/Navigation'
import notFound from "./pages/notFound";
import privacy_policy from "./pages/privacy_policy";
import imprint from "./pages/imprint";

const routes = [
  { name: 'start', path: '/', components: {default: start}, meta: {order: 0} },
  { name: 'step1', path: '/step-1', components: {default: step1, navigation: nav}, meta: {order: 1, next: 'step2'} },
  { name: 'step2', path: '/step-2', components: {default: step2, navigation: nav}, meta: {order: 2, next: 'step3'} },
  { name: 'step3', path: '/step-3', components: {default: step3, navigation: nav}, meta: {order: 3, next: 'step4'} },
  { name: 'step4', path: '/step-4', components: {default: step4, navigation: nav}, meta: {order: 4, next: 'step5'} },
  { name: 'step5', path: '/step-5', components: {default: step5, navigation: nav}, meta: {order: 5, next: 'result'} },
  { name: 'result', path: '/result', components: {default: result}, meta: {order: 6} },
  { name: '404', path: '*', components: {default: notFound}, meta: {order: 0} },
  { name: 'privacy-policy', path: '/datenschutz', components: {default: privacy_policy}, meta: {order: 0} },
  { name: 'imprint', path: '/impressum', components: {default: imprint}, meta: {order: 0} },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes // short for `routes: routes`
})

Vue.use(VueRouter);

Vue.config.productionTip = false

Vue.prototype.$globalConstants = {
  breakpoints: {
    mobileMax: 599,
    desktopMin: 600,
  }
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import { render, staticRenderFns } from "./Choices.vue?vue&type=template&id=f9c7f7f8&scoped=true&"
import script from "./Choices.vue?vue&type=script&lang=js&"
export * from "./Choices.vue?vue&type=script&lang=js&"
import style0 from "./Choices.vue?vue&type=style&index=0&id=f9c7f7f8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9c7f7f8",
  null
  
)

export default component.exports